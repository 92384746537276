@import "globals";

.hero {
  @media (min-width: 992px) {
    height: calc(100vh - 96px);
  }

  .hero__panel {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;

    h2 {
      font-weight: 900;
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 24px;
      text-align: center;
    }
    a.hero-btn {
      height: 56px;
      padding: 0 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      color: white;
      text-decoration: none;
      font-weight: 700;
    }

    img.hero-bg {
      max-width: 100%;
      //   height: 400px;
      margin: 40px 0 0;
    }
    &--dunkin {
      background-color: $dunkin-cream;
      a.hero-btn {
        background-color: $dunkin-orange;
        &:hover {
          background-color: darken($dunkin-orange, 10%);
        }
      }
    }
    &--dave {
      background-color: $chicken-redShade;
      a.hero-btn {
        background-color: $chicken-red;
        &:hover {
          background-color: darken($chicken-red, 10%);
        }
      }
    }
  }
}

.contact-us-box {
  background: $dunkin-cream;
  border-radius: 8px;
  overflow: hidden;
}

.contact-right {
  background: linear-gradient(223.56deg, #173c6a 0.74%, #2e77d3 102.3%);
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  color: white;
}

.icon-text {
  display: flex;
  align-items: center;
  gap: 8px;

  i {
    color: white;
    font-size: 24px;
  }
  span,
  a {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: white;
  }
}


