html,
body {
  font-family: "Montserrat", sans-serif;
  color: #344054;
}

.nav-link {
  color: #2e77d3;
  margin-right: 20px;
  font-weight: 500;
}
.nav-link.nav-link--special {
  display: block;
  background-color: #173c6a;
  color: white;
  border-radius: 4px;
  height: 44px;
  padding: 0 16px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

h2.heading {
  font-size: 33px;
  line-height: 44px;
  font-weight: bold;
  color: #173c6a;
  margin-bottom: 32px;
}
h2.heading span {
  display: block;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: #667085;
}
h2.heading.heading--center {
  text-align: center;
}

img {
  max-width: 100%;
}

.accordion.common-accordion .accordion-button {
  font-weight: 700;
  color: #173c6a;
}
.accordion.common-accordion .accordion-button i {
  color: #2e77d3;
}
.accordion.common-accordion .accordion-button:not(.collapsed) {
  background: #eaf1fb;
}

.h4 {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 16px;
  font-weight: bold;
}
.h4--all-caps {
  text-transform: uppercase;
}
.h4--white {
  color: white;
}
.h4--lightBlue {
  color: #c0d6f2;
}

.btn {
  height: 44px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: white;
  text-decoration: none;
  border: none;
  font-weight: 700;
}
.btn.btn-primary {
  background-color: #173c6a;
}
.btn.btn-primary:hover {
  background-color: #2e77d3;
}

footer {
  background-color: #eaf1fb;
  font-weight: medium;
}
footer .small {
  color: #667085;
  font-weight: normal;
}

@media (min-width: 992px) {
  .hero {
    height: calc(100vh - 96px);
  }
}
.hero .hero__panel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 20px;
}
.hero .hero__panel h2 {
  font-weight: 900;
  font-size: 48px;
  line-height: 60px;
  margin-bottom: 24px;
  text-align: center;
}
.hero .hero__panel a.hero-btn {
  height: 56px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: white;
  text-decoration: none;
  font-weight: 700;
}
.hero .hero__panel img.hero-bg {
  max-width: 100%;
  margin: 40px 0 0;
}
.hero .hero__panel--dunkin {
  background-color: #f7f2ef;
}
.hero .hero__panel--dunkin a.hero-btn {
  background-color: #ef6a00;
}
.hero .hero__panel--dunkin a.hero-btn:hover {
  background-color: #bc5300;
}
.hero .hero__panel--dave {
  background-color: #ffe2e3;
}
.hero .hero__panel--dave a.hero-btn {
  background-color: #860700;
}
.hero .hero__panel--dave a.hero-btn:hover {
  background-color: #530400;
}

.contact-us-box {
  background: #f7f2ef;
  border-radius: 8px;
  overflow: hidden;
}

.contact-right {
  background: linear-gradient(223.56deg, #173c6a 0.74%, #2e77d3 102.3%);
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  color: white;
}

.icon-text {
  display: flex;
  align-items: center;
  gap: 8px;
}
.icon-text i {
  color: white;
  font-size: 24px;
}
.icon-text span,
.icon-text a {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: white;
}