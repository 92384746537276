// Color Palette
// Dunkin colors
$dunkin-cream: #f7f2ef;
$dunkin-orange: #ef6a00;

// Dave's Hot Chicken Colors
$chicken-redShade: #ffe2e3;
$chicken-red: #860700;

// Neutral Palette
$n25: #fcfcfd;
$n50: #f9fafb;
$n100: #f2f4f7;
$n200: #eaecf0;
$n300: #d0d5dd;
$n400: #98a2b3;
$n500: #667085;
$n600: #475467;
$n700: #344054;
$n800: #1d2939;

// Primary Blue Palette
$blue25: #eaf1fb;
$blue200: #c0d6f2;
$blue400: #82ade5;
$blue500: #2e77d3;
$blue600: #173c6a;

html,
body {
  font-family: "Montserrat", sans-serif;
  color: $n700;
}

.nav-link {
  color: $blue500;
  margin-right: 20px;
  font-weight: 500;
  &.nav-link--special {
    display: block;
    background-color: $blue600;
    color: white;
    border-radius: 4px;
    height: 44px;
    padding: 0 16px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

h2 {
  &.heading {
    font-size: 33px;
    line-height: 44px;
    font-weight: bold;
    color: $blue600;
    margin-bottom: 32px;

    span {
      display: block;
      font-size: 18px;
      line-height: 32px;
      font-weight: 500;
      color: $n500;
    }

    &.heading--center {
      text-align: center;
    }
  }
}

img {
  max-width: 100%;
}

.accordion {
  &.common-accordion {
    .accordion-button {
      font-weight: 700;
      color: $blue600;

      i {
        color: $blue500;
      }
    }
    .accordion-button:not(.collapsed) {
      background: $blue25;
    }
  }
}

.h4 {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 16px;
  font-weight: bold;

  &--all-caps {
    text-transform: uppercase;
  }

  &--white {
    color: white;
  }
  &--lightBlue {
    color: $blue200;
  }
}

.btn {
  height: 44px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: white;
  text-decoration: none;
  border: none;
  font-weight: 700;
  &.btn-primary {
    background-color: $blue600;

    &:hover {
      background-color: $blue500;
    }
  }
}

footer {
  background-color: $blue25;
  font-weight: medium;

  .small {
    color: $n500;
    font-weight: normal;
  }
}